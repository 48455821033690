.App-GLDC-table-container-count-box {
	display: flex;
	width: 50%;
}

.App-GLDC-To-Be-Executed {
	color : #a3220c;
}

.App-GLDC-Success {;
	color : #333333;
}

.App-GLDC-Failure {
	color : #FFFFFF;
}

.App-body-GLDC-Right-Cell {
	display: block;
	text-align: right;
}

.App-body-GLDC-header-export {
	/* margin: auto; */
	margin: 0.5rem 1.5rem;
    border-left: 1px solid #d4d5d6;
    padding: 0 0 0 1.5rem;
    display: flex;
    align-items: center;
}

.App-GLDC-Exec-table-container-count {
	color : #a3220c;
	display : flex;
	margin: auto;
}

.App-GLDC-tab-container {
	display: flex;
	width : 100%;
	height: 100%;
	margin : 5px;
	overflow-y: scroll;
	flex-direction: column;
}

.App-body-GLDC-Header-Container {
	display: flex;
	height: 5%;
	border-bottom: 1px solid #7d7d7d;
}

.App-GLDC-tab-body {
	height: 94%;
	display: flex;
	flex-direction: column;
	overflow : scroll;
	margin-top : 5px;
	margin : 5px;
}

.App-GLDC-table-query {
	cursor: pointer;
}

.App-body-GLDC-Header-Title-Container {
	display: flex;
	flex-direction: row;
	width: 20%;
	margin-left: 2%;
}

.App-body-GLDC-Header-Title {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	margin: 2px;
}

.App-body-GLDC-Header-Title-Hamburger {
	display: block;
	margin: auto;
	margin-left: 0px;

}

.App-body-GLDC-Header-Title-Name {
	display: flex;
	margin: auto;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	color: #161616;
	width: 80%;
}

.App-GLDC-tab-body {
	height: 100%;
	width: 100%;
}

.App-GLDC-tab-body-tab-conatiner {
	display: flex;
	width: 100%;
	height: 100%;
}

.App-GLDC-tab-mongo-container {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	overflow: scroll;
}

.App-GLDC-table-container-search-box {
	display: flex;
	flex-direction: row;
	margin: auto;
	margin-right: 10px;
}

.App-GLDC-table-container-task-link {
	margin: 5px;
	width: 10%;
}

.App-GLDC-table-container-search-box {
	margin : auto;
	margin-right: 10px;
}

.App-GLDC-table-container-search-icon-box {
	margin-right: 10px;
	font-size: 16px;
}

.App-GLDC-tab-mongo-filter {
	display: flex;
	width: 100%;
	height: 13%;
	margin: 2px; /*remove*/
	border-bottom: 1px solid black;
	flex-direction: row;
}

.App-GLDC-tab-mongo-data-container {
	display: flex;
	width: 100%;
	height: 90%;
	flex-direction: column;
	overflow: scroll;
}

.App-GLDC-tab-mongo-filter .ant-collapse-item {
	width: 100%;
}

.App-GLDC-tab-mongo-filter .ant-collapse {
}

.App-GLDC-tab-mongo-form-label {
	display: flex;
}

.App-GLDC-tab-mongo-form {
	height: 100%;
	display: flex;
	width : 100%;
	margin: 2px; /*remove*/
}

.App-GLDC-tab-mongo-filter-form {
	width: 100%;
	height: 100%;
	display: flex;
	margin: 2px; /*remove*/
	flex-direction: column;
}

.App-GLDC-tab-mongo-filter .ant-form {
	display: flex;	
	flex-direction: row;
}

.GLDC-inline {
	display: flex;
	width: 100%;
	flex-direction: row;
}

.App-GLDC-tab-filter-button {
	display: flex;
	flex-direction: column;
	margin: auto;
	width: 10%;
}

.App-GLDC-tab-filter-space {
	display: flex;
	height: 10%;
	margin: auto;
}

.GLDC-inline .ant-picker {
	display: flex;
}

.GLDC-inline .ant-form-item {
	width: 40%;
}

.App-GLDC-tab-body-table-loader {
	display: flex;
	width: 100%;
	height: 90%;
}

.App-GLDC-tab-body-table-header-container {
	display: flex;
	margin: auto;
	width: 100%;
}


.App-GLDC-table-container-count {
	display: flex;
	margin: auto;
}

.App-GLDC-tab-body-table-container {
	margin: 5px;
	display: flex;
	height: 90%;
}

.App-GLDC-table-container-count {
	display: flex;
	margin: auto;
}

.App-Clickable-icons:hover {
	cursor: pointer;
}

.App-GS-Failure {
	background-color: #a94442;
	color : #FFFFFF;
}

.App-GS-Success {
	background-color: #A3D977 ;
	color : #333333;
}

.App-RJ-create-node-container {
	display: flex; 
  	position: fixed; 
  	z-index: 2;
  	margin: auto; 
  	left: 0;
  	top: 0;
  	width: 100%; 
  	height: 100%; 
  	overflow: scroll; 
  	background-color: rgba(0,0,0,0.4)
}

.App-RJ-create-node-modal-content {
	position: relative;
 	background-color: #ffffff ;
	margin-left: auto;
	margin-top: auto;
	padding: 0;
	display: flex;
	flex-direction: column;
	border: 1px solid #888;
	width: 95%;
	height: 93%;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
	-webkit-animation-name: animatetop;
	-webkit-animation-duration: 0.4s;
	animation-name: animatetop;
	animation-duration: 0.5s;
	overflow: scroll;
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {right:-300px; opacity:0}
  to {right:0; opacity:1}
}

.App-RJ-create-node-modal-header {
	border-bottom: 1px solid #EDEEF0;
  height: 7%;
 	display: flex;
  flex-direction: row;
}

.App-RJ-create-node-modal-back-icon {
	margin: auto;
 	width: 4%;
  cursor: pointer;
}

.App-body-RJ-header-export {
	margin: 0.5rem 1.5rem;
  border-left: 1px solid #d4d5d6;
  padding: 0 0 0 1.5rem;
  display: flex;
  align-items: center;
}

.App-RJ-create-node-modal-header-content {
	width: 95%;
	text-align: left;
	color: black;
	font-family: "Proxima Nova Semibold";
	font-size: 26px;
	font-weight: 600;
	letter-spacing: 0.3px;
	line-height: 25px;
	margin: auto ;
}

.App-RJ-create-node-modal-body {
	border-bottom: 1px solid #EDEEF0;
	height: 93%;
	display: flex;
	overflow: scroll;
	flex-direction: column;
}

.App-RJ-table-container-search-box {
	margin : auto;
	margin-right: 10px;
	display: flex;
	flex-direction: row;
}

.App-RJ-create-node-modal-body-search {
	background-color: lightblue;
	height: 3%;
}


.App-RJ-create-node-content {
	display: flex;
	width: 80%;
}