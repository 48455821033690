.App-DI-table-container {
	width: 100%;
  	height: 100%;
  	display: flex;
  	flex-direction: column;
  	overflow: scroll;
  	/* margin : auto 10px; */     /* -P */
}

.App-DI-table-container thead {
	position: sticky;
    top: 0px;
    margin: 0 0 0 0;
    height: 20px;
    vertical-align: middle !important;
}

.App-DI-table-container-header {
	display: flex;
	color: #161616;
  	font-family: "Proxima Nova";
  	/* font-size: 24px;
 	line-height: 29px;
 	height: 8%;
	letter-spacing: 0.3px;
 	padding: auto 10%;
 	border-bottom: 1px solid #EDEEF0; */     /* -P */
  	font-weight: 600;
  	font-size: 1.5rem;
	letter-spacing: 0.02rem;
 	flex-direction: row;
	min-height: 3rem;
	border-bottom: 1px solid #D4D5D6;
}

.App-DI-table-filter-loader {
	display: flex;
	height: 40px;
	width: 100px;
	margin: 10px;
	margin-top :15px;
}

.App-DI-table-container-search {
	/* height: 8%; */     /* -P */
	min-height: 3rem;         /* -P */
	display: flex;
	flex-direction: row;
}

.App-DI-table-container-search-box {
	margin : auto;
	/* margin-right: 10px; */     /* -P */
	margin-right: 1.5rem;         /* -P */
}

.App-DI-table-container-search-icon-box {
	margin-right: 10px;
	font-size: 16px;
}

.App-DI-table-loader {
	width: 100%;
	height: 100%;
	display: flex;
}

.App-DI-table-container-header-title {
	/* margin: auto 10px; */
	margin: auto 1.5rem;
	float: left;
}

.App-DI-table-container-header-filter {
    display: flex;
    flex-direction: row;
    margin: auto;
}

.App-DI-table-container-filter {
	float : left;
	/* width : 20%; */    /* -P */
}

.App-DI-table-container-header-add-new {
	display: flex;
	margin: auto 10px;
	margin-left: auto;
}

.App-DI-table-container-category {
	margin: 5px;
	background-color: red;
}

.App-DI-red-dot {
	height: 10px;
 	width: 10px;
 	background-color: red;
  	border-radius: 50%;
  	display: inline-block;
 	margin : auto 10px auto 2px;
}

.App-DI-purple-dot {
	height: 10px;
 	width: 10px;
 	background-color: purple;
  	border-radius: 50%;
  	display: inline-block;
 	margin : auto 10px auto 2px;
}

.App-DI-table-container-table {
	/* overflow: scroll;
	height: 90%; */          /* -P */
	height: 90%;
	margin-left: 1.5rem;     /* -P */
	margin-right: 1.5rem;    /* -P */
	overflow: hidden;        /* -P */
}

.App-DI-table-container-table .ant-collapse-expand-icon {
	margin-top: -2px;
}

.App-DI-table-container-table .ant-collapse-header-text {
	text-align: left;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    /* font-size: 14px;
    letter-spacing: 0.3px; */     /* -P */
	font-size: 1rem;              /* -P */
	letter-spacing: 0.02rem;      /* -P */
    color: #161616;
}

.App-DI-table-container-table .ant-collapse.ant-collapse-icon-position-start {
	overflow: auto;
    height: 100%;
}  /* -P */

.App-DI-table-header {
	background-color: #EDEEF0;
	display: flex;
	/* height: 6%; */        /* -P */
	min-height: 2.5rem;      /* -P */
	flex-direction: row;
}

.App-DI-table-header-expand {
	/* width: 35px;
	margin: 5px 0px; */     /* -P */
	width: 2rem;            /* -P */
	margin: auto 0;         /* -P */
	width: 5%;
	display: block;
}

.App-DI-table-header-cluster-name {
	width: 95%;
	/* margin : 2px 0px; */     /* -P */
	margin: auto 0;             /* -P */
	display: flex;
	border-right: 1px solid #D4D5D6;
}

.App-DI-table-panel-cluster {
	width: 100%;
	display: flex;
	flex-direction: row;
}

.App-DI-table-panel-cluster-name {
	width: 90%;
	display: flex;
	border-right: 1px solid #D4D5D6;
}

.App-DI-table-panel-cluster-edit {
	width: 2rem;
}

.App-DI-table-panel-cluster-name-text {
	font-family: 'Proxima Nova';
	font-style: normal;
	/* font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.3px; */     /* -P */
	font-size: 0.9rem;            /* -P */
	line-height: 0.9rem;          /* -P */
	letter-spacing: 0.02rem;      /* -P */
	color: #161616;
	text-align: left;
	display: flex;
	margin: auto 0.6rem;          /* -P */
	/* margin: auto 10px;
	float: left; */               /* -P */
}

.App-DI-table-header-cluster-name-text {
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	/* font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.3px; */     /* -P */
	font-size: 0.9rem;            /* -P */
	line-height: 0.9rem;          /* -P */
	letter-spacing: 0.02rem;      /* -P */
	color: #161616;
	text-align: left;
	display: flex;
	margin: auto 0.6rem;          /* -P */
	/* margin: auto 10px;
	float: left; */               /* -P */
}

.App-DI-table-header-settings {
	/* margin : 2px 0px;
	border-right: 1px solid #D4D5D6;
	width: 2.5%; */              /* -P */
	width: 5%;                 /* -P */
	display: block;
}