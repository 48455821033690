.App-UUI-table-container-count-box {
	display: flex;
	width: 50%;
}

.App-UUI-Status-container {
	
}

.App-UUI-Success {
	background-color: green;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: block;
}

.App-UUI-Fail {
	background-color: red;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: block
}
