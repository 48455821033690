.table {
    border-spacing: 0;
}

.new-table {
	display: inline-block;
	float: left;
}

.th,
.td {
	margin: 0;
	position: relative;
	border-color: inherit;
	padding: .5rem auto;
	display: flex !important;
	flex-direction: column;
}

.th {
	padding: .5rem auto;
	display: flex !important;
	flex-direction: column;
	border-width: 0 0 0 1px;
}

.th-content {
	margin : auto 10px;
	width: 90%;
}

.resizer {
    display: inline-block;
    background: #DEDFE0;
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action:none; 
}

.App-table-header-sticky {
	border-collapse: collapse;
  	padding: 0.38rem 0;                  /* -P */
  	background-color: #F4F4F4;
  	border-right: 1px solid #DEDFE0;
  	color: #161616;
  	text-align: left;
  	font-family: "Proxima Nova";
  	/* font-size: 14px;
  	letter-spacing: 0.3px;
  	line-height: 14px; */               /* -P */
	font-size: 0.9rem;                  /* -P */
	letter-spacing: 0.02rem;            /* -P */
	line-height: 0.9rem;                /* -P */
  	font-weight: 600;
  	flex-direction: row;
  	width: 100%;
}

.App-table-row-sticky {
	padding: 0.5rem 0;
  	border-collapse: collapse;
  	text-align: left;
  	/* height : 30px; */                /* -P */
	height: 2rem;                       /* -P */
  	border-bottom : 1px solid #D4D5D6;
  	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 400;
	/* font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.3px; */           /* -P */
	font-size: 0.9rem;                  /* -P */
	letter-spacing: 0.02rem;            /* -P */
	line-height: 0.9rem;                /* -P */
	color: #161616;
}

.App-table-header-sticky .tr div.th:last-child{
	position : sticky !important;
	right : 0px;
	width: 2% !important;
	background-color: #F4F4F4;
	border-left: 1px solid #DEDFE0;
	z-index: 1;
}

.App-table-row-sticky .tr div.td:last-child {
	position : sticky !important;
	right : 0px;
	width: 2% !important;
	background-color: #fff;
	border-left: 1px solid #DEDFE0;
	z-index: 1;
}

.App-table-col-sticky {
	position : sticky !important;
	right : 0px;
	width: 2% !important;
	background-color: #fff;
	border-left: 1px solid #DEDFE0;
	z-index: 1;
	display: flex;
}

.App-table-col-sticky .dropdown {
	margin: auto;
}

.App-table-col-header-sticky {
	position : sticky !important;
	right : 0px;
	width: 2% !important;
	background-color: #F4F4F4;
	border-left: 1px solid #DEDFE0;
	z-index: 1;
	display: flex;
}

.App-table-col-header-sticky img {
	margin: auto;
}
