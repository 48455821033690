.App-DB-Filter-container {
	display: flex;
	flex-direction: row;
	/* width: 90%;
	height: 60%;
	margin: 5% 0;
	padding: 1% 0; */        /* -P */
	height: 100%;            /* -P */
	margin-left: 1.5rem;     /* -P */
}

.App-DB-Filter-icon {
	/* width: 20%;
	padding: 4% auto; */      /* -P */
	margin : auto 0 ;
	position: relative;
  	display: inline-block;
  	cursor: pointer;
	line-height: 1rem;      /* -P */
}

.App-DB-Filter-icon img {
	/* width : 40%;
	height: 40%; */      /* -P */
	width: 1rem;         /* -P */
	height: 1rem;        /* -P */
	cursor: pointer;
}


.App-DB-Filter-Reset {
	/* width: 70%;
	float : right;
	margin: auto; */      /* -P */
	display: flex;
	flex-direction: row;
	margin-left: 1rem;
    font-size: 0.9rem;    /* -P */
}

.App-DB-Filter-Reset-label {
	color: #4285f4;
	margin: auto;
	margin-right: 1rem;    /* -P */
}

.App-DB-Filter-Reset-label:hover {
	color : #4285f4;
	cursor : pointer;
}

.App-DB-Filter-Reset-seperator {
	margin: auto;
	margin-right: 1rem;    /* -P */
}

.App-DB-Filter-Reset-result {
	margin: auto;
}

.App-DB-Filter-icon .popuptext {
  width: 100vh;
  background-color: white;
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
  height: 40vh;
  overflow: scroll;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 2;
  top :100%;
  left: 50%;
  margin-left: -10px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

/* Popup arrow */
.App-DB-Filter-icon .popuptext::after {
  content: "";
  position: absolute;
  bottom: 100%;
  right: 98%;
  margin-right: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #555 transparent;
}

/* Toggle this class - hide and show the popup */
.App-DB-Filter-icon .show {
  visibility: visible;
  -webkit-animation: fadeIn 1s;
  animation: fadeIn 1s;
}

/* Add animation (fade in the popup) */
@-webkit-keyframes fadeIn {
  from {opacity: 0;} 
  to {opacity: 1;}
}

@keyframes fadeIn {
  from {opacity: 0;}
  to {opacity:1 ;}
}

.App-DB-Filter-Popup-title {
	height: 10%;
	display: flex;
	flex-direction: row;
}

.App-DB-Filter-Popup-Close {
	margin : auto 3% auto auto;
	font-size: 1.2rem;
	color : black;
}

.App-DB-Filter-Popup-Container {
	width: 100%;
	height: 80%;
	display: flex;
	flex-direction: column;
	margin: auto;
	overflow: scroll;
}

.App-DB-Filter-Footer {
	height: 15%;
	display: flex;
	flex-direction: row;
}

.App-DB-Filter-AddFilter {
	/* width: 20%;
	height: 90%;
	margin: auto auto auto 1%;
	text-align: center; */
	margin: auto auto auto 1.5rem;
	color: #4285f4;
	display: flex;
}

.App-DB-Filter-AddFilter-Label {
	margin: auto;
	font-size: 0.9rem;
    font-weight: 500;
}

.App-DB-Filter-ResetFilter {
	width: 10%;
	height: 90%;
	margin: auto 2% auto auto;
	color : #4285f4;
	display: flex;
	text-align: center;
	/* padding: auto; */
	width: 6rem;
    height: 2rem;
    border-radius: 3px;
    margin: auto 0 auto 1rem;
}

.App-DB-Filter-ResetFilter-Label {
	margin: auto;
	font-size: 0.9rem;
    font-weight: 500;
}

.App-DB-Filter-ApplyFilter-Label {
	margin: auto;
	color : #fff;
	font-size: 0.9rem;
    font-weight: 500;
}

.App-DB-Filter-ApplyFilter {
	/* width: 15%;
	height: 60%;
	margin: auto 5% auto 1%; */
	border-color: #4285f4;
	background-color: #4285f4;
	display: flex;
	justify-content: center;
	width: 6rem;
    height: 2rem;
    border-radius: 3px;
    margin: auto 1.5rem auto 1rem;
}