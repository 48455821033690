.App-DB-FilterUnitCotainer {
	display: flex;
	flex-direction: row;
	/* width: 90%;
	margin: 1% auto;
	height: 15%; */
	margin: 0.75rem 0.5rem 0;
}

.App-DB-FilterUnitField {
	width: 30%;
	/* height: 95%; */
	margin: auto;
}

.App-DB-FilterUnitComparator {
	width: 25%;
	/* height: 95%; */
	margin: auto;
}

.App-DB-FilterUnitValue {
	width: 30%;
	/* height: 95%; */
	margin: auto;
}

.App-DB-FilterUnitSelect {
	width: 100%;
	/* height: 95%; */
	margin: auto;
} 

.App-DB-FilterUnitDelete {
	width: 5%;
	/* height: 95%; */
	margin: auto;
	display: flex;
}

.App-DB-FilterUnitDelete img {
	font-size: 16px;
    /* width: 50%;
    height: 50%;
    margin: 15% 10%; */
}