.App-TLC-tab-container {
	display: flex;
	width : 100%;
	height: 100%;
	margin : 5px;
	overflow-y: scroll;
	flex-direction: column;
}

.App-body-TLC-Header-Container {
	display: flex;
	height: 5%;
	border-bottom: 1px solid #7d7d7d;
}

.App-body-TLC-Header-Title-Container {
	display: flex;
	flex-direction: row;
	width: 20%;
	margin-left: 2%;
}

.App-body-TLC-Header-Title {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 90%;
	margin: 2px;
	overflow: scroll;
}

.App-body-TLC-Header-Title-Name {
	display: flex;
	margin: auto;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	color: #161616;
	width: 90%;
}

.App-body-TLC-header-export {
	margin: 0.5rem 1.5rem;
  	border-left: 1px solid #d4d5d6;
  	padding: 0 0 0 1.5rem;
  	display: flex;
  	align-items: center;
}

.App-TLC-tab-body {
	height: 94%;
	display: flex;
	flex-direction: column;
	overflow : scroll;
	margin-top : 5px;
	margin : 5px;
}

.App-TLC-tab-mongo-container {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	overflow: scroll;
}

.App-TLC-tab-mongo-filter {
	display: flex;
	width: 100%;
	height: 13%;
	border-bottom: 1px solid black;
}

.App-TLC-tab-mongo-form {
	height: 100%;
	display: flex;
	width : 100%;
}

.App-TLC-tab-mongo-filter-form {
	width: 100%;
	height: 100%;
	display: flex;
}

.App-TLC-tab-mongo-filter .ant-form {
	display: flex;	
	height: 100%;
	width: 100%;
	flex-direction: row;
}

.TLC-inline {
	display: flex;
	width: 100%;
	flex-direction: row;
}

.App-TLC-tab-form-loader {
	display: flex;
	margin: auto;
}

.App-TLC-tab-mongo-data-container {
	display: flex;
	width: 100%;
	height: 90%;
	flex-direction: column;
	overflow: scroll;
}

.App-TLC-tab-body-table-header-container {
	display: flex;
	margin: auto;
	width: 100%;
}

.App-TLC-table-container-count-box {
	display: flex;
	width: 30%;
}

.App-TLC-table-container-count {
	display: flex;
	margin: auto;
}

.App-TLC-table-container-search-box {
	margin : auto;
	margin-right: 10px;
	display: flex;
	flex-direction: row;
}

.App-TLC-tab-body-table-container {
	margin: 5px;
	display: flex;
	height: 90%;
}

.App-TLC-tab-body-table-loader {
	display: flex;
	width: 100%;
	height: 90%;
}