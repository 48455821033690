.App-body-CS-Container {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
}

.App-body-CS-Outer-Container {
	display: flex;
	width: 100%;
	height: 100%;
}

.App-body-CS-Header-Container {
	display: flex;
	/* height: 8%; */
	min-height: 3rem;
	border-bottom: 1px solid #D4D5D6;
}

.App-body-CS-Body-Container {
	display: flex;
	width: 100%;
	height: 100%;
	/* height: 92%; */
	overflow: scroll;
}

.App-body-CS-Right-Cell-Header {
	/* width: 50%;
	margin-left: 50%; */
	text-align: right;
}

.App-body-CS-Right-Cell {
	/* margin-left : 65%;
	width: 35%; */
	text-align: right;
}

.App-body-CS-Header-Title-Container {
	display: flex;
	flex-direction: row;
	/* width: 20%;
	margin-left: 2%; */
	margin: auto 1.5rem;
    float: left;
}

.App-body-CS-Header-Title {
	display: flex;
	flex-direction: row;
	/* width: 100%;
	height: 100%;
	margin: 2px; */
}

.App-body-CS-Header-Title-Hamburger {
	display: block;
	margin: auto;
	margin-left: 0px;

}

.App-body-CS-Header-Title-Name {
	display: flex;
	margin: auto;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	/* font-size: 20px;
	line-height: 32px; */
	font-size: 1.5rem;
	letter-spacing: 0.02rem;
	line-height: 2rem;
	margin-left: 0.5rem;
	color: #161616;
	/* width: 80%; */
}

.App-body-CS-Header-Date-Container {
	display: flex;
	/* width: 40%;
	height: 70%;
	margin: auto;
	margin-left: 50%; */
	margin: auto 1.5rem;
    margin-left: auto;
}

.App-body-CS-Path-Link {
	color : #4285F4;
	margin : 0 0.5rem;
 	text-decoration:none;
}

.App-body-CS-Header-Date {
	display: flex;
	/* margin: 5px;
	width: 90%; */
}

.App-body-CS-Body-Outer-Container {
	background-color: #F5F5F5;
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.App-body-CS-Body-Path-Container {
	/* width: 95%;
	margin: auto; */
	margin-left: 1.5rem;
	min-height: 3rem;
    padding-top: 0.5rem;
	display: flex;
}

.App-body-CS-Body-Table {
	/* width: 95%;
	height: 90%;
	margin: auto; */
	display: flex;
	background-color: white;
	flex-direction: column;
	overflow: scroll;
	margin: 0 1.5rem 1.5rem;
    border-radius: 0.25rem;
	height: 100%;
	margin-top: 1rem;
}

.App-body-CS-Body-Table-Header {
	border-bottom: 1px solid #D4D5D6;
	/* height: 8%; */
	display: flex;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	/* font-size: 18px; */
	font-size: 1.2rem;
	min-height: 3rem;
}

.App-body-CS-Body-Table-Header-Title {
	/* width: 15%;
	height: 90%;
	margin-left: 10px; */
	display: flex;
	margin-left: 1.5rem;
}

.App-body-CS-Body-Table-Header-Data {
	margin: auto;
}

.App-body-CS-Body-Table-Body {
	/* height: 92%; */
	height: 100%;
	display: flex;
	margin: 1.5rem;
	overflow: hidden;
}

.App-CS-table-container-table {
	overflow: scroll;
	/* height: 90%; */
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.App-CS-table-container-table .ant-collapse-header {
	padding: 2px 0 2px 1rem;
}

.App-CS-table-container-table .ant-collapse-expand-icon {
	align-self: center;
    margin-top: -6px;
	margin-right: 6px;
}

.App-CS-table-container-table .ant-collapse-header-text {
	text-align: left;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
	/* font-size: 14px;
    letter-spacing: 0.3px; */
    font-size: 0.9rem;
    letter-spacing: 0.02rem;
    color: #161616;
}

.App-CS-table-container-table .ant-collapse-content-box {
	padding: 1rem 0;
    padding-left: 1.5rem;
}


.App-CS-table-header {
	background-color: #EDEEF0;
	display: flex;
	/* height: 6%; */
	min-height: 2.4rem;
	width: 100%;
	flex-direction: row;
}

.App-CS-table-header-content {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-left: 8px;
}

.App-CS-table-header-data {
	display: flex;
	height: 6%;
	width: 100%;
	flex-direction: row;
}


.App-CS-table-header-data-cs-name-text {
	font-family: 'Proxima Nova';
	font-style: normal;
	/* font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.3px; */
	font-size: 0.9rem;
	line-height: 1.5rem;
	letter-spacing: 0.02rem;
	color: #161616;
	text-align: left;
	display: flex;
	margin: auto 6px auto 0;
	float: left;
}

.App-CS-table-container .App-table-container-body {
	border-left : 2px solid #4285F4;
}

.App-CS-table-header-expand {
	width: 42px;
	margin: 5px 0px;
}

.App-CS-table-header-cs-month-name {
	/* width: 85%; */
	margin : 0;
	display: flex;
	border-right: 1px solid #D4D5D6;
	width: 5.5rem;
	float: left;
}

.App-CS-table-header-cs-name-text {
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	/* font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.3px; */
	font-size: 0.9rem;
	line-height: 1.5rem;
	letter-spacing: 0.02rem;
	color: #161616;
	text-align: left;
	display: flex;
	margin: auto 10px auto 0;
	float: left;
}

.App-CS-table-header-data-cs-savings-header-text {
	font-family: 'Proxima Nova';
	font-style: normal;
	float : right;
	font-size: 0.9rem;
	line-height: 1.5rem;
	letter-spacing: 0.02rem;
	font-weight: 600;
	color: #161616;
	text-align: right;
	display: flex;
	margin: auto 0;
	width: 100%;
	margin-right: 1rem;
}

.App-CS-table-header-data-cs-savings-text {
	font-family: 'Proxima Nova';
	font-style: normal;
	float : right;
	/* font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.3px; */
	font-size: 0.9rem;
	line-height: 1.5rem;
	letter-spacing: 0.02rem;
	color: #161616;
	text-align: left;
	display: flex;
	/* margin: auto 10px;
	float: left; */
	width: 100%;
}

.App-CS-table-header-cs-savings {
	/* width: 15%;
	float: right; 
	margin: 2px 0px;
	border-right: 1px solid #D4D5D6; */
	margin: 0 0 0 auto;
	display: flex;
}

.App-CS-table-header-settings {
	margin : 2px 0px;
	border-right: 1px solid #D4D5D6;
	width: 2.5%;
}

.App-CS-table-container-table .ant-collapse-content-box .App-table-container-body-table {
	margin-left: 1rem;
	margin-right: 1rem;
}