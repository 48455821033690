.App-CS-tab-container {
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow: scroll;
}

.App-CS-tab-container-header {
	display: flex;
	color: #161616;
  	font-family: "Proxima Nova";
  	font-size: 1.5rem;
  	font-weight: 600;
  	letter-spacing: 0.02rem;
 	line-height: 29px;
 	/* height: 5%;
 	padding: auto 10%;  */
 	flex-direction: row;
	min-height: 3rem;
 	border-bottom: 1px solid #D4D5D6;
}

.App-CS-tab-container-header-title {
	display: flex;
	/* margin : auto 10px; */
	margin: auto 1.5rem;
}

.App-CS-tab-chart-container {
	width: 100%;
	/* height: 75%; */
	height: 100%;
	display: flex;
	flex-direction: column;
	background-color: #F5F5F5;
}

.App-CS-tab-chart-title-header {
	display: flex;
	color: #161616;
  	font-family: "Proxima Nova";
  	font-size: 1rem;
  	font-weight: 400;
  	letter-spacing: 0.02rem;
 	line-height: 29px;
 	flex-direction: row;
 	width: 100%;
	/* height: 10%;
	background : #F4F4F4;
 	padding: auto 10%; */
	 margin-left: 1.5rem;
	 min-height: 3rem;
	 padding-top: 0.5rem;
}

.App-CS-tab-chart-title  {
	display: flex;
	/* margin: auto 10px; */
}

.App-CS-tab-chart-graph {
	display: flex;
	flex-direction: column;
	overflow: auto;
	background-color: white;
	/* width: 98%;
	margin: auto;
	height: 95%; */
	margin: 0 1.5rem 1.5rem;
    border-radius: 0.25rem;
    height: 100%;
}

.App-CS-tab-chart-graph-title-header {
	/* height: 10%; */
	border-bottom: 1px solid #D4D5D6;
	display: flex;
	min-height: 3rem;
}

.App-CS-tab-chart-graph-container {
	/* width: 90%; */
	display: flex;
	position: relative;
	height: 100%;
	min-height: 20rem;
	padding: 1.5rem;
}

.App-CS-tab-chart-graph-title {
	display: flex;
	color: #161616;
  	font-family: "Proxima Nova";
  	font-size: 1.2rem;
  	font-weight: 600;
  	letter-spacing: 0.02rem;
 	/* width : 15%; 
 	margin: auto 10px; */
	margin-left: 1.5rem;
}

.App-CS-tab-chart-graph-title-word {
	margin: auto;
}

.App-CS-tab-drilldown-container {
	/* height: 45%; */
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: #F5F5F5;
}

.App-CS-tab-drilldown-inner {
	display: flex;
	/* height: 95%;
	width: 98%;
	margin: auto; */
	flex-direction: column;
	overflow: auto;
	background-color: white;
	margin: auto 1.5rem 1.5rem;
}

.App-CS-tab-drilldown-title-header {
	display: flex;
	width: 100%;
	/* height: 15%; */
	border-bottom: 1px solid #D4D5D6;
	min-height: 3rem;
}

.App-CS-tab-drilldown-title {
	display: flex;
	color: #161616;
  	font-family: "Proxima Nova";
  	font-size: 1.2rem;
  	font-weight: 600;
  	letter-spacing: 0.02rem;
 	/* width : 15%;
 	margin: auto 10px; */
	margin-left: 1.5rem;
}

.App-CS-tab-drilldown-title-word {
	margin: auto;
}

.App-CS-tab-drilldown {
	display: flex;
	/* height: 90%; */
	overflow: scroll;
	width: 100%;
}

.App-CS-tab-drilldown .App-body-DR-Body-Outer-Container {
	background-color: #fff;
}

.App-CS-tab-drilldown .App-body-DR-Body-Path-Container {
	margin-left: 1rem;
}

.App-CS-tab-drilldown .App-body-DR-Body-Table {
	margin: 0;
}

.App-CS-tab-drilldown .App-body-DR-Body-Table-Body {
	margin-top: 0;
}