.App-AC-tab-container {
	display: flex;
	width : 100%;
	height: 100%;
	/* margin : 5px; */
	overflow-y: scroll;
	flex-direction: column;
}

.App-body-AC-header-container {
	display: flex;
	flex-direction: row;
	color: #161616;
  	font-family: "Proxima Nova";
	font-weight: 600;
  	font-size: 1.5rem;
	letter-spacing: 0.02rem;
	/* height: 6%;
	border-bottom: 1px solid #7d7d7d; */
	min-height: 3rem;
	border-bottom: 1px solid #D4D5D6;
}

.App-body-AC-header-title-container {
	display: flex;
	flex-direction: row;
	/* width: 90%; */
	width: 100%;
	margin: auto 0;
}

.App-body-AC-header-title {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	/* margin: 2px; */
}

.App-body-AC-header-title-name {
	display: flex;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	/* font-size: 25px;
	line-height: 32px; */
	font-size: 1.5rem;
	letter-spacing: 0.02rem;
	color: #161616;
	/* width: 80%;
	margin: auto 2%; */
	margin: auto 0 auto 1.5rem;
    width: 100%;
}

.App-body-AC-header-month-range {
	/* margin-left: 3%; */
	display: flex;
}

.App-body-AC-header-export {
	/* margin: auto; */
	margin: 0.5rem 1.5rem;
    border-left: 1px solid #d4d5d6;
    padding: 0 0 0 1.5rem;
    display: flex;
    align-items: center;
}

.App-body-AC-header-export img {
	height: 1rem;
    width: 1rem;
	cursor: pointer;
}

.App-body-AC-tab-body {
	/* height: 94%;
	margin-top : 5px; */
	display: flex;
	flex-direction: column;
	overflow : scroll;
	margin-top: 1.5rem;
    height: 100%;
}

.App-body-AC-tab-body-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
}

.App-body-AC-tab-body-overall-cost-container {
	/* height: 20%; */
	display: flex;
	flex-direction: column;
}

.App-body-AC-tab-body-overall-cost-title {
	display: flex;
	/* height: 15%;
	margin: auto 2%; */
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	font-size: 1.15rem;
	line-height: 32px;
	color: #161616;
	margin: 0 1.5rem 0.5rem;
}

.App-body-AC-tab-body-overall-cost-table {
	display: flex;
	/* height: 75%;
	margin: auto 2%; */
	margin: auto 0;
    height: 100%;
}

.App-body-AC-tab-body .App-table-container-body-table {
	height: 100%;
}

.App-body-AC-tab-body .App-table-container-body-table .App-table-header {
	text-align: right;
}

.App-body-AC-tab-body-project-cost-container {
	/* height: 80%; */
	display: flex;
	flex-direction: column;
	margin-top: 3rem;
}

.App-body-AC-tab-body-project-cost-title {
	display: flex;
	/* height: 5%;
	margin: 1% 2%; */
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	font-size: 1.15rem;
	line-height: 32px;
	color: #161616;
	margin: 0 1.5rem 0.5rem;
}

.App-body-AC-tab-body-project-cost-table {
	display: flex;
	/* height: 15%; 
	margin: 1% 2%; */
	margin: 0;
    margin-bottom: 2rem;
}