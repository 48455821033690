.App-CL-table-container {
	width: 100%;
  	height: 100%;
  	display: flex;
  	flex-direction: column;
  	overflow: scroll;
  	/* margin : auto 10px; */
}

.App-CL-table-container thead {
	position: sticky;
    top: 0px;
    margin: 0 0 0 0;
    height: 20px;
    vertical-align: middle !important;
}

.App-CL-table-container-header {
	display: flex;
	color: #161616;
  	font-family: "Proxima Nova";
  	/* font-size: 24px;
  	font-weight: bolder;
  	letter-spacing: 0.3px;
 	line-height: 29px;
 	height: 8%;
 	padding: auto 10%; */
	font-weight: 600;
	font-size: 1.5rem;
	letter-spacing: 0.02rem;
	min-height: 3rem;
	border-bottom: 1px solid #D4D5D6;
	padding: 0 0.9rem;
 	flex-direction: row;
}

.App-CL-table-container-header-title {
	margin: auto 10px;
	float: left;
}

.App-CL-table-container-header-filter {
    display: flex;
    flex-direction: row;
    margin: auto;
}

.App-CL-table-container-header-add-new {
	display: flex;
	margin: auto 10px;
	margin-left: auto;
	background-color: purple;
}

.App-CL-table-container-category {
	margin: 5px;
	background-color: red;
}

.App-CL-table-container-search {
	height: 8%;
	display: flex;
	flex-direction: row;
	min-height: 3rem;         /* -P  */
	margin-right: 1rem;       /* -P  */
}

.App-CL-table-container-search-box {
	margin : auto;
	margin-right: 10px;
}

.App-CL-table-container-search-icon-box {
	margin-right: 10px;
	font-size: 16px;
}
