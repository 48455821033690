.App-AL-tab-mongo-container {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
}

.App-AL-tab-mongo-inner-container {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	overflow: scroll;
}

.App-AL-tab-mongo-filter {
	display: flex;
	width: 100%;
	/* height: 13%;
	border-bottom: 1px solid black; */
	padding: 0 1.2rem 1rem;
    min-height: 5.5rem;
    border-bottom: 1px solid #D4D5D6;
}

.App-body-AL-header-export {
	/* margin: auto; */
	margin: 0.5rem 1.5rem;
    border-left: 1px solid #d4d5d6;
    padding: 0 0 0 1.5rem;
    display: flex;
    align-items: center;
}

.App-AL-tab-mongo-data-container {
	display: flex;
	width: 100%;
	 height: 90%; 
	flex-direction: column;
	overflow: scroll;
}

.App-AL-table-container-search-box {
	margin: auto;
	margin-right: 10px;
	display: flex;
	flex-direction: row;
}

.App-AL-tab-mongo-filter .ant-collapse-item {
	width: 100%;
}

.App-AL-tab-mongo-filter .ant-collapse {
}

.App-AL-tab-mongo-form-label {
	display: flex;
}

.App-AL-tab-mongo-form {
	height: 100%;
	display: flex;
	width : 100%;
}

.App-AL-tab-mongo-filter-form {
	width: 100%;
	height: 100%;
	display: flex;
}

.App-AL-tab-mongo-filter .ant-form {
	display: flex;	
	height: 100%;
	width: 100%;
	flex-direction: row;
}

.AL-inline {
	display: flex;
	width: 100%;
	flex-direction: row;
}

.App-AL-tab-filter-button {
	display: flex;
	flex-direction: column;
	margin: auto;
	width: 10%;
}

.App-AL-tab-filter-space {
	display: flex;
	height: 10%;
	margin: auto;
}

.AL-inline .ant-form-item {
	width: 40%;
	text-align: left;
}

.App-AL-tab-body-table-loader {
	display: flex;
	width: 100%;
	height: 100%;
}

.App-AL-tab-body-table-container {
	/* margin: 5px; */
	display: flex;
	height: 90%; 
    margin-top: 1rem;
    overflow: scroll;
}