.App-body-block-left-tab-icon {
  /* height: 5%;
  width: 75%; */      /* -P */
  height: 2.4rem;     /* -P */
  width: 2.5rem;      /* -P */
  background-color: #5C6470;
  border-radius: 1px;
  /* box-shadow: 0 2px 0 0 #00BFA5; 
  margin: 15% 10%; */    /* -P */
  border-bottom: 0.2rem solid #00BFA5;        /* -P */
  margin: 1rem auto 0;                          /* -P */
  cursor: pointer;
}

.App-body-block-left-tab-icon-title {
  color: #FFFFFF;
  font-family: "Proxima Nova";
  /* font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.3px;
  margin : 20% 15%; */      /* -P */
  font-size: 1rem;          /* -P */
  line-height: 1rem;        /* -P */
  font-weight: 600;
  text-align: center;
  margin: 0.7rem auto;      /* -P */
}
