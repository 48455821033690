.App-DA-table-container {
	width: 100%;
  	height: 100%;
  	display: flex;
  	flex-direction: column;
  	overflow: scroll;
  	/* margin : auto 10px; */
}

.App-DA-table-container-header {
	display: flex;
	color: #161616;
  	font-family: "Proxima Nova";
  	/* font-size: 24px;
  	font-weight: bolder;
  	letter-spacing: 0.3px;
 	line-height: 29px;
 	height: 8%;
 	padding: auto 10%;
 	border-bottom: 1px solid #EDEEF0; */
	font-weight: 600;
	font-size: 1.5rem;
	letter-spacing: 0.02rem;
	min-height: 3rem;
	border-bottom: 1px solid #D4D5D6;
	padding: 0 0.9rem;
 	flex-direction: row;
}

.App-DA-table-container-header-title {
	margin: auto 10px;
	float: left;
}

.App-DA-table-container-body {
	/* height: 92%; */
	height: 100%;
    overflow: scroll;
	display: flex;
}

.App-DA-table-container-body .ant-tabs {
	width: 100%;
	margin: 0 auto;
}

.App-DA-table-container-body .ant-tabs-tab-active {
	font-family: "Proxima Nova Semibold";
}

.App-DA-table-container-body .ant-tabs-nav {
	width: 100%;
	background-color: #EDEEF0;
	font-family: "Proxima Nova";
	/* font-size: 20px; */
	font-size: 1.25rem;
	margin: 0;
	padding-left: 1.5rem;
}

.App-DA-table-container-body .ant-tabs-nav-list {
	width: 100%;
}

.App-DA-table-container-body .ant-tabs-tab {
	/* width: 8%; */
	padding: auto;
}

.App-DA-table-container-body .ant-tabs-tab-btn {
	margin: auto;
	line-height: 1rem;
}

.App-DA-table-container-body .ant-tabs-tabpane {
	height: 100%;
}

.App-DA-table-container-body .ant-tabs-content {
	height: 100%;
}

.App-DA-tab-container {
	height: 100%;
}