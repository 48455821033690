.App-title-block {
  width : 100%;
  /* height : 7%; */    /* -P */
  height: 4rem;         /* -P */
  background-color : #3F4757;
  display: flex;
  position : fixed;
  top: 0;              /* -P */
  z-index: 2;          /* -P */
  flex-direction: row;
  /* border-bottom: solid #161616 1px; */    /* -P */
}

.App-title-block-9-icon {
  height: 100%;
  /* width: 5%; */    /* -P */
  width: 4rem;        /* -P */
  color : white;
  justify-content: center;
  align-items: center;
  /* border-right: solid #161616 1px; */    /* -P */
  padding: 1px;
}

.App-title-block-logout {
  margin: auto;
  /* margin-left: 80%; */
  margin-right: 1rem;
  display: flex;
  font-family: "Proxima Nova";
  /* font-size: 18px; */    /* -P */
  font-size: 1.1rem;        /* -P */
  font-weight: 600;
  color: white;
  cursor: pointer;
}

.App-title-block-title {
  /* width: 8%; */    /* -P */
  width: 8rem;        /* -P */
  margin: auto 2%;    /* -P */
}

.App-title-block-title-logo {
  max-height: 100%;
  max-width: 100%;
}

.App-title-block-title-safari {
  width: 8rem;
  display: flex;
  margin: auto 2%;
  display: flex;
  height: 100%;
}

.App-title-block-title-logo-safari {
  display: flex;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
  scale : 2;
  -webkit-transform : rotate(-90deg);
}

.App-title-block-9-image {
  cursor: pointer;
}

.icon-nine-dots-menu {
  height: 24px;
  width: 24px;
  margin: 20px;
  font-size: 1.5rem;
}
