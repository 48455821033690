.App-body-CSum-Container {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
}

.App-body-CSum-Outer-Container {
	display: flex;
	width: 100%;
	height: 100%;
}

.App-body-CSum-Header-Container {
	display: flex;
	/* height: 8%; */
	min-height: 3rem;
	border-bottom: 1px solid #D4D5D6;
}

.App-body-CSum-Body-Container {
	display: flex;
	width: 100%;
	height: 100%;
	/* height: 92%; */
	overflow: scroll;
}

.App-body-CSum-Right-Cell-Header {
	/* width: 50%;
	margin-left: 50%; */
	text-align: right;
}

.App-body-CSum-Right-Cell {
	text-align: right;
}


.App-body-CSum-Header-Title-Container {
	display: flex;
	flex-direction: row;
	/* width: 20%;
	margin-left: 2%; */
	margin: auto 1.5rem;
    float: left;
}

.App-body-CSum-Header-Title {
	display: flex;
	flex-direction: row;
	/* width: 100%;
	height: 100%;
	margin: 2px; */
}

.App-body-CSum-Header-Title-Hamburger {
	display: block;
	margin: auto;
	margin-left: 0px;
	display: none;
}

.App-body-CSum-Header-Title-Name {
	display: flex;
	margin: auto;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	/* font-size: 20px;
	line-height: 32px; */
	font-size: 1.5rem;
	letter-spacing: 0.02rem;
	line-height: 2rem;
	margin-left: 0.5rem;
	color: #161616;
	/* width: 80%; */
}

.App-body-CSum-Header-Date-Container {
	display: flex;
	/* width: 40%;
	height: 70%;
	margin: auto;
	margin-left: 50%; */
	margin: auto 1.5rem;
    margin-left: auto;
}

.App-body-CSum-Path-Link {
	color : #4285F4;
	margin : 0 0.5rem;
 	text-decoration:none;
}

.App-body-CSum-Header-Date {
	display: flex;
	/* margin: 5px;
	width: 90%; */
}

.App-body-CSum-Body-Outer-Container {
	background-color: #F5F5F5;
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.App-body-CSum-Body-Path-Container {
	/* width: 95%;
	margin: auto; */
	margin-left: 1.5rem;
	min-height: 3rem;
    padding-top: 0.5rem;
	display: flex;
}

.App-body-CSum-Body-Table {
	/* width: 95%;
	height: 90%;
	margin: auto; */
	display: flex;
	background-color: white;
	flex-direction: column;
	overflow: scroll;
	margin: 0 1.5rem 1.5rem;
    border-radius: 0.25rem;
	height: 100%;
	margin-top: 1rem;
}

.App-body-CSum-Body-Table-Header {
	border-bottom: 1px solid #D4D5D6;
	/* height: 8%; */
	display: flex;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	/* font-size: 18px; */
	font-size: 1.2rem;
	min-height: 3rem;
}

.App-body-CSum-Body-Table-Header-Title {
	/* width: 15%;
	height: 90%;
	margin-left: 10px; */
	display: flex;
	margin-left: 1.5rem;
}

.App-body-CSum-Body-Table-Header-Data {
	margin: auto;
}

.App-body-CSum-Body-Table-Body {
	/* height: 92%; */
	height: 100%;
	display: flex;
	margin-top: 0;
	overflow: hidden;
}

.App-CSum-table-container-table {
	overflow: scroll;
	/* height: 90%; */
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
}

.App-CSum-table-container-table .ant-collapse-header-text {
	text-align: left;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    /* font-size: 14px;
    letter-spacing: 0.3px; */
	font-size: 0.9rem;
    letter-spacing: 0.02rem;
    color: #161616;
}

.App-body-CSum-Filter {
	display: flex;
	/* height: 10%; */
	min-height: 3rem;
}

.App-body-CSum-Filter-Toggles {
	/* width: 30%; */
	margin: auto;
	display: flex;
	flex-direction: row;
	margin-left: 1.5rem;
}

.App-body-CSum-Filter-Toggle {
	/* width: 50%; 
	margin: auto; */
	margin-right: 3rem;
	display: flex;
}

.App-CSum-table-header {
	background-color: #EDEEF0;
	display: flex;
	/* height: 6%; */
	min-height: 2.4rem;
	width: 100%;
	flex-direction: row;
}

.App-CSum-table-header-content {
	width: 100%;
	display: flex;
	flex-direction: row;
	/* margin: 0 15px; */
	margin-left: 8px;
}

.App-CSum-table-header-data {
	display: flex;
	height: 6%;
	width: 100%;
	flex-direction: row;
}


.App-CSum-table-header-data-CSum-name-text {
	font-family: 'Proxima Nova';
	font-style: normal;
	/* font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.3px; */
	font-size: 0.9rem;
	line-height: 1.5rem;
	letter-spacing: 0.02rem;
	color: #161616;
	text-align: left;
	display: flex;
	/* margin: auto 10px; */
	margin: auto 6px auto 0;
	float: left;
}

.App-CSum-table-container .App-table-container-body {
	border-left : 2px solid #4285F4;
}

.App-CSum-table-header-expand {
	/* width: 35px; */
	width: 42px;
	margin : 5px 0px;
}

.App-CSum-table-header-CSum-month-name {
	/* width: 85%;
	margin : 2px 0px; */
	margin: 0;
	display: flex;
	border-right: 1px solid #D4D5D6;
	width: 5.5rem;
	float: left;
}

.App-CSum-table-header-CSum-name-text {
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	/* font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.3px; */
	font-size: 0.9rem;
	line-height: 1.5rem;
	letter-spacing: 0.02rem;
	color: #161616;
	text-align: left;
	display: flex;
	/* margin: auto 10px; */
	margin: auto 10px auto 0;
	float: left;
}

.App-CSum-table-header-data-CSum-savings-header-text {
	font-family: 'Proxima Nova';
	font-style: normal;
	float : right;
	/* font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.3px; */
	font-size: 0.9rem;
	line-height: 1.5rem;
	letter-spacing: 0.02rem;
	font-weight: 600;
	color: #161616;
	/* text-align: left; */
	text-align: right;
	display: flex;
	/* margin: auto 10px; */
	margin: auto 0;
	/* float: left; */
	width: 100%;
	margin-right: 1rem;
}

.App-body-CSum-Filter-Toggle-Label {
	font-family: "Proxima Nova";
	margin: auto;
	line-height: 14px;
	letter-spacing: 0.3px;
	color: #161616;
	padding-left: 0.5rem;
}

.App-CSum-table-header-data-CSum-savings-text {
	font-family: 'Proxima Nova';
	font-style: normal;
	float : right;
	/* font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.3px; */
	font-size: 0.9rem;
	line-height: 1.5rem;
	letter-spacing: 0.02rem;
	color: #161616;
	text-align: left;
	display: flex;
	/* margin: auto 10px;
	float: left; */
	width: 100%;
}

.App-CSum-table-header-CSum-savings {
	/* width: 15%;
	float : right;
	margin : 2px 0px;
	border-right: 1px solid #D4D5D6; */
	display: flex;
	margin: 0 0 0 auto;
}

.App-CSum-table-header-settings {
	margin : 2px 0px;
	border-right: 1px solid #D4D5D6;
	width: 2.5%;
}