.App-body-DR-Container {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
}

.App-body-DR-Outer-Container {
	display: flex;
	width: 100%;
	height: 100%;
}

.App-body-DR-Header-Container {
	display: flex;
	/* height: 8%; */
	min-height: 3rem;
	border-bottom: 1px solid #D4D5D6;
}

.App-body-DR-Body-Container {
	display: flex;
	width: 100%;
	height: 100%;
	/* height: 92%; */
	overflow: scroll;
}

.App-body-DR-Header-Title-Container {
	display: flex;
	flex-direction: row;
	/* width: 15%;
	margin-left: 2%; */
	margin: auto 1.5rem;
    float: left;
}

.App-body-DR-Header-Title {
	display: flex;
	flex-direction: row;
	/* width: 100%;
	height: 100%;
	margin: 2px; */
}

.App-body-DR-Header-Title-Hamburger {
	display: block;
	margin: auto;
	margin-left: 0px;

}

.App-body-DR-Header-Title-Name {
	display: flex;
	margin: auto;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	/* font-size: 20px;
	line-height: 32px; */
	font-size: 1.5rem;
	letter-spacing: 0.02rem;
	line-height: 2rem;
	color: #161616;
	margin-left: 0.5rem;
}

.App-body-DR-Header-Date-Container {
	display: flex;
	/* width: 40%;
	height: 70%;
	margin: auto;
	margin-left: 60%; */
	margin: auto 1.5rem;
    margin-left: auto;
}

.App-body-DR-Path-Link {
	color : #4285F4;
	margin : 0 0.5rem;
 	text-decoration:none;
}

.App-body-DR-Header-Date {
	display: flex;
	/* margin: 5px;
	width: 90%; */
}

.App-body-DR-Body-Outer-Container {
	background-color: #F5F5F5;
	width: 100%;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.App-body-DR-Body-Path-Container {
	/* width: 95%;
	margin: auto; */
	margin-left: 1.5rem;
	min-height: 3rem;
    padding-top: 0.5rem;
	display: flex;
}

.App-body-DR-Body-Table {
	/* width: 95%;
	height: 90%;
	margin: auto; */
	display: flex;
	background-color: white;
	flex-direction: column;
	overflow: scroll;
	margin: 0 1.5rem 1.5rem;
    border-radius: 0.25rem;
	height: 100%;
}

.App-body-DR-Right-Cell {
	float : right;
}

.App-body-DR-Body-Table-Header {
	border-bottom: 1px solid #D4D5D6;
	/* height: 8%; */
	display: flex;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	/* font-size: 18px; */
	font-size: 1.2rem;
	min-height: 3rem;
}

.App-body-DR-Body-Table-Header-Title {
	/* width: 15%;
	height: 90%;
	margin-left: 10px; */
	display: flex;
	margin-left: 1.5rem;
}

.App-body-DR-Body-Table-Header-Data {
	margin: auto;
}

.App-body-DR-Body-Table-Body {
	/* height: 92%; */
	height: 100%;
	display: flex;
	margin-top: 1rem;
	overflow: hidden;
}