.App-AL-tab-container {
	display: flex;
	width : 100%;
	height: 100%;
	/* margin : 5px; */
	overflow-y: scroll;
	flex-direction: column;
}

.App-body-AL-Header-Container {
	display: flex;
	/* height: 5%;
	border-bottom: 1px solid #7d7d7d; */
	min-height: 3rem;
	border-bottom: 1px solid #D4D5D6;
}

.App-AL-table-query {
	cursor: pointer;
}

.App-body-AL-Header-Title-Container {
	display: flex;
	flex-direction: row;
	/* width: 20%;
	margin-left: 2%; */
	margin-left: 1.5rem;
	float: left;
}

.App-body-AL-Header-Title {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	/* margin: 2px; */
}

.App-body-AL-Header-Title-Hamburger {
	display: block;
	margin: auto;
	margin-left: 0px;

}

.App-body-AL-Header-Title-Name {
	display: flex;
	margin: auto;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	/* font-size: 20px;
	line-height: 32px; */
	font-size: 1.5rem;
	letter-spacing: 0.02rem;
	line-height: 2rem;
	margin-left: 0.5rem;
	color: #161616;
	/* width: 80%; */
}

.App-AL-tab-body {
	/* height: 94%; */
	display: flex;
	flex-direction: column;
	overflow : scroll;
	/* margin-top : 5px;
	margin : 5px; */
	width: 100%;
	height: 100%;
}

.App-AL-tab-body .ant-tabs-nav {
	width: 100%;
	background-color: #EDEEF0;
	font-family: "Proxima Nova";
	/* font-size: 20px; */
	font-size: 1.25rem;
	margin: 0;
	padding-left: 1.5rem;
}

.App-AL-tab-body-tab-conatiner {
	display: flex;
	width: 100%;
	height: 100%;
}

.App-AL-tab-body .ant-tabs {
    height: 100%;
    width: 100%;
}

.App-AL-tab-body .ant-tabs-content-holder {
	height: 100%;
	display: flex;
}

.App-AL-tab-body .ant-tabs-tabpane {
    height: 100%;
    width: 100%;
}

.App-AL-tab-body .ant-tabs-tab-btn {
	margin: auto;
	line-height: 1rem;
}

.App-AL-tab-body .ant-tabs-tab-active {
	font-family: "Proxima Nova Semibold";
	font-weight: 500;
}