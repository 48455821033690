.App-CP-table-container {
	width: 100%;
  	height: 100%;
  	display: flex;
  	flex-direction: column;
  	overflow: scroll;
  	margin : auto 10px;
}

.App-CP-table-container thead {
	position: sticky;
    top: 0px;
    margin: 0 0 0 0;
    height: 20px;
    vertical-align: middle !important;
}

.App-CP-table-container-header {
	display: flex;
	color: #161616;
  	font-family: "Proxima Nova";
  	font-size: 24px;
  	font-weight: bolder;
  	letter-spacing: 0.3px;
 	line-height: 29px;
 	height: 8%;
 	padding: auto 10%;
 	flex-direction: row;
}

.App-CP-table-container-header-title {
	margin: auto 10px;
	float: left;
}

.App-CP-table-container-header-filter {
    display: flex;
    flex-direction: row;
    margin: auto;
}

.App-CP-table-container-header-add-new {
	display: flex;
	margin: auto 10px;
	margin-left: auto;
	background-color: purple;
}

.App-CP-table-container-category {
	margin: 5px;
	background-color: red;
}

.App-CP-table-container-search {
	height: 8%;
	display: flex;
	flex-direction: row;
}

.App-CP-table-container-search-box {
	margin : auto;
	margin-right: 10px;
}

.App-CP-table-container-search-icon-box {
	margin-right: 10px;
	font-size: 16px;
}