.App-body-block {
  display: block;
  width : 100%;
  height: 100%;
}

.App-body-block-upper-title-tab {
  width: 100%;
  /* height: 7%; */     /* -P */
  height: 4rem;         /* -P */
}

.App-body-block-content {
  width: 100%;
  /* height: 93%; */              /* -P */
  height: calc(100vh - 4rem);     /* -P */
  display: flex;
  flex-direction: row;
}

.App-body-block-left-tab {
  /* width : 5%; */     /* -P */
  width: 4rem;          /* -P */
  background-color : #3F4757;
  position: fixed;      /* -P */
  height: 100%;         /* -P */
  left: 0;              /* -P */
}

.App-body-block-right-tab {
  /* width : 95%; */             /* -P */
  width: calc(100vw - 4rem);     /* -P */
  margin-left: 4rem;             /* -P */
}
