.App-CP-create-node-container {
  display: flex; 
  position: fixed; 
  z-index: 1;
  margin: auto; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: scroll; 
  background-color: rgba(0,0,0,0.4)
}

.App-CP-create-node-modal-content {
  position: relative;
  background-color: #ffffff ;
  /* margin-left: auto;
  margin-top: auto;
  width: 95%;
  height: 93%; */
  width: calc(100vw - 4rem);
  height: calc(100vh - 4rem);
  margin-top: 4rem;
  margin-left: 4rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.5s;
  overflow: scroll;
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {right:-300px; opacity:0}
  to {right:0; opacity:1}
}

.App-CP-create-node-modal-header {
  /* border-bottom: 1px solid #EDEEF0;
  height: 7%; */
  min-height: 3rem;
  border-bottom: 1px solid #D4D5D6;
  display: flex;
  flex-direction: row;
}

.App-CP-create-node-modal-back-icon {
  margin: auto;
  width: 4%;
  cursor: pointer;
}

.App-CP-create-node-modal-header-content {
  width: 95%;
  text-align: left;
  color: black;
  font-family: "Proxima Nova Semibold";
  font-size: 26px;
  font-weight: 600;
  letter-spacing: 0.3px;
  line-height: 25px;
  margin: auto ;
}

.App-CP-create-node-modal-body {
  border-bottom: 1px solid #EDEEF0;
  height: 93%;
  display: block;
  overflow: scroll;
}

.App-CP-create-node-modal-footer {
  display: flex;
  /* height: 10%;
  width: 100%; */
  min-height: 4rem;
}

.App-CP-create-node-form-container {
  margin: 20px 2%;
  width: 50%;
  height: 90%;
  font-family: 'Proxima Nova';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.002em;
  color: #444F5C;
}

.inline {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.inline .ant-form-item {
  width: 45%;
}

.App-CP-create-node-footer-button-container {
  display: flex;
  margin: auto;
  /* margin-right : 5%; */      /* -P */
  margin-right: 0.9rem;         /* -P */
}

.App-CP-create-node-footer-button-container button {
  margin: 10px;
}

.App-CP-create-node-form-container .ant-select-selector {
  height: 35px !important;
}
