.App-login-container {
	display : flex;;
	width: 100%;
	height: 100%;
	flex-direction: row;
}

.App-login-form-container {
	display: flex;
	width: 41%;
	height: 100%;
	flex-direction: column;
	/*background: palegoldenrod;*/
}

.App-login-logo-container {
	display: flex;
	width: 59%;
	background: radial-gradient(95.91% 151.31% at 0.96% 95.38%, #1E0386 0%, #1D0381 25.83%, #06054B 100%)
}

.App-login-form-header {
	margin : 5px 20%;
	margin-top : 25%;
	height: 10%;
	/*background: yellow;*/
}

.App-login-form-body-header-name {
	margin: auto;
}

.App-login-form-body {
	margin: 0 20%;
	height: 100%;
	display: flex;
	flex-direction: column;
	/*background: purple;*/
}

.App-login-form-body-header {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: bold;
	font-size: 20px;
	line-height: 16px;
	letter-spacing: 0.3px;
	height: 10%;
	margin : 5px 20%;
	display: flex;
	/*background: paleturquoise;*/
}

.App-login-form-body-contents{
	height: 90%;
	display: flex;
	flex-direction: column;
}


.App-login-form-body-email-container {
	height: 20%;
	margin: 10px auto;
	width: 85%;
	display: flex;
	flex-direction: column;
}

.App-login-form-body-email-label {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: normal;
	font-size: 16px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: black;
	height: 20%;
	text-align: left;
	margin: 5px;
}

.App-login-form-body-email-text-area {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: black;
	height: 50%;
	width: 100%;
	border: 1px solid #00BFA5;
	box-sizing: border-box;
	border-radius: 5px;
	margin: auto 0px;
}

.App-login-form-body-password-label {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: #FFFFFF;
	height: 20%;
}

.App-login-form-body-password-text-area {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: 0.3px;
	color: black;
	height: 50%;
	width: 90%;
	border: 1px solid #00BFA5;
	box-sizing: border-box;
	border-radius: 5px;
	margin: auto 0px;
}

.App-login-form-body-password-container {
	height: 15%;
	margin: 10px 10px 10px 0px;
	width: 75%;
	display: flex;
	flex-direction: column;
}

.App-login-form-body-login-button-container {
	height: 15%;
	width: 75%;
	display: flex;
	flex-direction: column;
	margin: 10px auto;
}

.App-login-form-body-login-button {
	width : 90%;
	display: flex;
	background: purple;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 8px 16px;
	border: none;
	background: #0060FF;
	border-radius: 2px;
	margin: auto;
	cursor: pointer;
}

.App-login-form-body-login-label {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.3px;
	color: #FFFFFF;
}

.App-login-form-body-login-label-disabled {
	font-family: Proxima Nova;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	letter-spacing: 0.3px;
	color: #FFFFFF;
	cursor: not-allowed;
}

.App-login-form-logo-container {
	display: flex;
	width : 50%;
}


.App-login-logo-div {
	margin: auto;
	width: 100%;
}