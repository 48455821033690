.mini-bouncing-loader {
  display: flex;
  margin: auto;
  width: 50%;
  justify-content: center;
}

.mini-bouncing-loader > div {
  width: 10px;
  height: 10px;
  margin: auto 3px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  background-color: yellow;
  animation: mini-bouncing-loader 0.6s infinite alternate;
}

@keyframes mini-bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-10px);
  }
}

.mini-bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
  background-color: green;
}

.mini-bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
  background-color: blue;
}