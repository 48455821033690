.App-SQ-tab-container {
	display: flex;
	width : 100%;
	height: 100%;
	/* height: 100vh; 
	margin : 5px; */
	overflow-y: scroll;
	flex-direction: column;
}

.App-body-SQ-Header-Container {
	display: flex;
	/* height: 5%;
	border-bottom: 1px solid #7d7d7d; */
	min-height: 3rem;
	border-bottom: 1px solid #D4D5D6;
}

.App-SQ-tab-body {
	/* height: 94%; */
	display: flex;
	flex-direction: column;
	overflow : scroll;
	/* margin-top : 5px */
	width: 100%;
	height: 100%;
}

.App-SQ-table-query {
	cursor: pointer;
}

.App-body-SQ-Header-Title-Container {
	display: flex;
	flex-direction: row;
	/* width: 20%;
	margin-left: 2%; */
	margin-left: 1.5rem;
	float: left;
}

.App-body-SQ-Header-Title {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	/* margin: 2px; */
}

.App-body-SQ-Header-Title-Hamburger {
	display: block;
	margin: auto;
	margin-left: 0px;

}

.App-body-SQ-Header-Title-Name {
	display: flex;
	margin: auto;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	/* font-size: 20px;
	line-height: 32px; */
	font-size: 1.5rem;
	letter-spacing: 0.02rem;
	line-height: 2rem;
	margin-left: 0.5rem;
	color: #161616;
	/* width: 80%; */
}

.App-SQ-tab-form-loader {
	display: flex;
	margin: auto;
}

.App-body-SQ-Header-Date-Container {
	display: flex;
	/* width: 40%;
	height: 70%;
	margin: auto;
	margin-left: 50%; */
	margin: auto 1.5rem;
    margin-left: auto;
}

.App-body-SQ-Header-Date {
	display: flex;
	margin: 5px;
	width: 90%;
}

.App-SQ-tab-body-filter-container {
	/* height: 10%;
	margin: 5px;
	border-bottom: 1px solid #7d7d7d; */
	overflow: scroll;
	display: flex;
	padding: 0.5rem 1.2rem 1rem;
    min-height: 5.5rem;
	border-bottom: 1px solid #D4D5D6;
}

.App-SQ-tab-body-table-container {
	/* height: 80%;
	margin: 5px; */
	height: 100%;
	margin: 1rem 0 0;
	overflow: scroll;
}

.App-SQ-tab-body-table-loader {
	height: 60%;
	display: flex;
}

.App-SQ-tab-form {
	display: flex;
	width: 100%;
	/* height: 90%; */
	height: 100%;
}

.App-SQ-tab-filter-form {
	width: 100%;
}

.SQ-inline {
	display: flex;
	width: 100%;
	flex-direction: row;
}

.SQ-inline .ant-form-item {
	width: 40%;
	text-align: left;
}

.SQ-double-inline {
	display: flex;
	width: 100%;
	flex-direction: row;
}

.SQ-double-inline .ant-form-item {
	width: 20%;
	margin-right: auto;
}

.SQ-button-inline {
	display: flex;
	flex-direction: row;
	width: 40%;
}

.SQ-button-inline button {
	margin-right: 10px;
	width: 30%;
}