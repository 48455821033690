.App-drag-table-container-body {
	height: 92%;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	border-left: 2px solid #4285F4
}

.App-drag-table-container-body-table {
	height: 92%;
	overflow: scroll;;
}

.table {
    border-spacing: 0;
}

.draggable-new-table {
	display: inline-block;
	width: 100%;
}

.draggable-th,
.draggable-td {
	margin: 0;
	position: relative;
	border-color: inherit;
	padding: .5rem auto;
	display: flex !important;
	flex-direction: column;
}

.draggable-th {
	padding: .5rem auto;
	display: flex !important;
	flex-direction: column;
	border-width: 0 0 0 1px;
}

.draggable-td {
	padding: .5rem auto;
	flex-direction: column;
	display: flex !important;
}

.draggable-th-content {
	margin : auto 10px;
	width: 90%;
}

.App-drag-table-row-cell .resizer {
	display: none;
}

.resizer {
    display: inline-block;
    background: #DEDFE0;
    width: 1px;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action:none; 
}

.App-drag-table-header {
	border-collapse: collapse;
  	padding: 0.5rem;
  	background-color: #F4F4F4;
  	border-right: 1px solid #DEDFE0;
  	color: #161616;
  	text-align: left;
  	font-family: "Proxima Nova";
  	font-size: 14px;
  	font-weight: 600;
  	letter-spacing: 0.3px;
  	line-height: 14px;
  	flex-direction: row;
  	padding: auto;
  	width: 100%;
}

.App-drag-table-row {
	padding: 0.5rem;
  	border-collapse: collapse;
  	text-align: left;
  	height : 30px;
  	border-bottom : 1px solid #D4D5D6;
  	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.3px;
	color: #161616;
}


.App-drag-table-header-sticky {
	border-collapse: collapse;
  	padding: 0.5rem 0;
  	background-color: #F4F4F4;
  	border-right: 1px solid #DEDFE0;
  	color: #161616;
  	text-align: left;
  	font-family: "Proxima Nova";
  	font-size: 14px;
  	font-weight: 600;
  	letter-spacing: 0.3px;
  	line-height: 14px;
  	flex-direction: row;
  	padding: auto;
  	width: 100%;
}

.App-drag-table-row-sticky {
	padding: 0.5rem 0;
  	border-collapse: collapse;
  	text-align: left;
  	height : 30px;
  	border-bottom : 1px solid #D4D5D6;
  	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.3px;
	color: #161616;
}

.App-drag-table-header-sticky .tr div.th:last-child{
	position : sticky !important;
	right : 0px;
	width: 2% !important;
	background-color: #F4F4F4;
	border-left: 1px solid #DEDFE0;
	z-index: 1;
}

.App-drag-table-row-sticky .tr div.td:last-child {
	position : sticky !important;
	right : 0px;
	width: 2% !important;
	background-color: #fff;
	border-left: 1px solid #DEDFE0;
	z-index: 1;
}


.App-drag-table-col-sticky {
	position : sticky !important;
	right : 0px;
	width: 2% !important;
	background-color: #fff;
	/*border-left: 1px solid #DEDFE0;*/
	z-index: 1;
	display: flex;
}

.App-drag-table-col-sticky .dropdown {
	margin: auto;
}

.App-drag-table-col-header-sticky {
	position : sticky !important;
	right : 0px;
	width: 2% !important;
	background-color: #F4F4F4;
	border-left: 1px solid #DEDFE0;
	z-index: 1;
	display: flex;
}

.App-drag-table-col-header-sticky img {
	margin: auto;
}
