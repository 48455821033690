.App-SQ-create-node-container {
		display: flex; 
  	position: fixed; 
  	z-index: 2;
  	margin: auto; 
  	left: 0;
  	top: 0;
  	width: 100%; 
  	height: 100%; 
  	overflow: scroll; 
  	background-color: rgba(0,0,0,0.4)
}

.App-SQ-create-node-modal-content {
	position: relative;
  background-color: #ffffff ;
	margin-left: auto;
	margin-top: auto;
	padding: 0;
	display: flex;
	flex-direction: column;
	border: 1px solid #888;
	width: 95%;
	height: 93%;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
	-webkit-animation-name: animatetop;
	-webkit-animation-duration: 0.4s;
	animation-name: animatetop;
	animation-duration: 0.5s;
	overflow: scroll;
}

@-webkit-keyframes animatetop {
  from {top:-300px; opacity:0} 
  to {top:0; opacity:1}
}

@keyframes animatetop {
  from {right:-300px; opacity:0}
  to {right:0; opacity:1}
}

.App-SQ-create-node-modal-header {
	border-bottom: 1px solid #EDEEF0;
  	height: 7%;
 	display: flex;
  	flex-direction: row;
}

.App-SQ-create-node-modal-back-icon {
	margin: auto;
 	width: 4%;
  	cursor: pointer;
}

.App-DCD-modal-table {
	display: flex;
	margin: 10px;
}

.App-SQ-create-node-modal-header-content {
	width: 95%;
	text-align: left;
	color: black;
	font-family: "Proxima Nova Semibold";
	font-size: 26px;
	font-weight: 600;
	letter-spacing: 0.3px;
	line-height: 25px;
	margin: auto ;
}

.App-SQ-create-node-modal-body {
	border-bottom: 1px solid #EDEEF0;
	height: 93%;
	display: block;
	overflow: scroll;
}

.App-SQ-create-node-content {
	display: flex;
	width: 80%;
}