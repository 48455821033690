.App-DB-pageTile-container {
	display: flex;
	flex-direction: row;
	/* width : 20%;
	margin: 10px;
	height: 50px;
	margin-top: 20px; */             /* -P */
	width: 22%;
	min-width: 15rem;
	margin: 0.8rem;                  /* -P */
	height: 3rem;                    /* -P */
	margin-top: 1.5rem;              /* -P */
	cursor: pointer;
}

.App-DB-pageTile-icon {
	/* width: 20%; */              /* -P */
	min-width: 3rem;               /* -P */
	background: #D4D5D6;
	border-radius: 2px;
	height: 100%;
	display: flex;
}

.App-DB-pageTile-icon-title {
	margin: auto;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	/* identical to box height */

	text-align: center;
	letter-spacing: 0.3px;
	color: #000000;
}

.App-DB-pageTile-name {
	width: 80%;
	text-align: left;
	padding: 10px;
	margin: auto;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: 0.3px;
	color: #161616;
}
