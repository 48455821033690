.App-RJS-tab-container {
	display: flex;
	width : 100%;
	height: 100%;
	margin : 5px;
	overflow-y: scroll;
	flex-direction: column;
}

.App-body-RJS-Header-Container {
	display: flex;
	height: 5%;
	border-bottom: 1px solid #7d7d7d;
}

.App-RJS-tab-body {
	height: 94%;
	display: flex;
	flex-direction: column;
	overflow : scroll;
	margin-top : 5px;
	margin : 5px;
}

.App-RJS-table-query {
	cursor: pointer;
}

.App-body-RJS-Header-Title-Container {
	display: flex;
	flex-direction: row;
	width: 20%;
	margin-left: 2%;
}

.App-body-RJS-Header-Title {
	display: flex;
	flex-direction: row;
	width: 100%;
	height: 100%;
	margin: 2px;
}

.App-body-RJS-Header-Title-Hamburger {
	display: block;
	margin: auto;
	margin-left: 0px;

}

.App-body-RJS-Header-Title-Name {
	display: flex;
	margin: auto;
	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 600;
	font-size: 20px;
	line-height: 32px;
	color: #161616;
	width: 80%;
}

.App-RJS-tab-body {
	height: 100%;
	width: 100%;
}

.App-RJS-tab-body-tab-conatiner {
	display: flex;
	width: 100%;
	height: 100%;
}

.App-RJS-tab-mongo-container {
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	overflow: scroll;
}

.App-RJS-table-container-search-box-container {
	display: flex;
	flex-direction: row;
	margin: auto;
	width: 100%;
}

.App-RJS-table-container-task-link {
	margin: 5px;
	width: 10%;
}

.App-RJS-table-container-search-box {
	margin : auto;
	margin-right: 10px;
}

.App-RJS-table-container-search-icon-box {
	margin-right: 10px;
	font-size: 16px;
}

.App-RJS-tab-mongo-filter {
	display: flex;
	width: 100%;
	height: 13%;
	border-bottom: 1px solid black;
}

.App-RJS-tab-mongo-data-container {
	display: flex;
	width: 100%;
	height: 90%;
	flex-direction: column;
	overflow: scroll;
}

.App-RJS-tab-mongo-filter .ant-collapse-item {
	width: 100%;
}

.App-RJS-tab-mongo-filter .ant-collapse {
}

.App-RJS-tab-mongo-form-label {
	display: flex;
}

.App-RJS-tab-mongo-form {
	height: 100%;
	display: flex;
	width : 100%;
}

.App-RJS-tab-mongo-filter-form {
	width: 100%;
	height: 100%;
	display: flex;
}

.App-RJS-tab-mongo-filter .ant-form {
	display: flex;	
	height: 100%;
	width: 100%;
	flex-direction: row;
}

.RJS-inline {
	display: flex;
	width: 100%;
	flex-direction: row;
}

.App-RJS-tab-filter-button {
	display: flex;
	flex-direction: column;
	margin: auto;
	width: 10%;
}

.App-RJS-tab-filter-space {
	display: flex;
	height: 10%;
	margin: auto;
}

.RJS-inline .ant-picker {
	display: flex;
}

.RJS-inline .ant-form-item {
	width: 40%;
}

.App-RJS-tab-body-table-loader {
	display: flex;
	width: 100%;
	height: 90%;
}

.App-RJS-tab-body-table-header-container {
	display: flex;
	margin: auto;
	width: 100%;
}

.App-RJS-table-container-count-box {
	display: flex;
	width: 30%;
}

.App-RJS-table-container-count {
	display: flex;
	margin: auto;
}

.App-RJS-tab-body-table-container {
	margin: 5px;
	display: flex;
	height: 90%;
}