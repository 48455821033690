.App-body-block-left-tab-container {
  width : 100%;
  height : 100%;
  display: flex;
  flex-direction: column;
}

.App-body-block-left-tab-icon-container {
  height: 92%;
  display: flex;
  flex-direction: column;
}

.App-body-block-left-tab-avatar-container {
  height: 8%; 
  /* border-top : solid black 1px; */    /* -P */
}
