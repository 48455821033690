.App-DB-Landing-page-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.App-DB-Landing-page-header {
  /* height: 10%; */     /* -P */
  height: 3.6rem;        /* -P */
  display: flex;
  padding: 10px;
}

.App-DB-Landing-page-body {
  height: 90%;
  display: flex;
  padding: 0 10px;      /* -P */
}

.App-DB-Landing-page-body .ant-tabs {
  width: 100%;
}

.App-DB-Landing-page-body .ant-tabs-nav-wrap {
  /* height: 50px; */
  border-bottom: 1px solid #EDEEF0;
}

.App-DB-Landing-page-body .ant-tabs-nav {
  margin-left: 0.8rem;     /* -P */
  height: 4rem;            /* -P */
}

.App-DB-Landing-page-body .ant-tabs-nav-list {
  align-items: flex-end;
}      /* -P */

.App-DB-Landing-page-body .ant-tabs-tab {
  /* width : 200px; */           /* -P */
  width: 12rem;                  /* -P */
  text-align: center;
  margin : auto;
  /* background-color: pink; *   /* -P */
  border-radius: 0.4rem 0.4rem 0 0 !important;      /* -P */
  height: 3rem;                 /* -P */
  font-size: 1rem !important;   /* -P */
  letter-spacing: 0.02rem;      /* -P */
}

.App-DB-Landing-page-body .ant-tabs-tab-active {
  /* border-top: 1px solid #1890ff !important; */          /* -P */
  border-top-color: #4285f4 !important;                  /* -P */
  border-width: 0.2rem 0.1rem 0.1rem 0.1rem !important;    /* -P */
  font-weight: 600;
  height: 3.6rem;                                          /* -P */
}

.App-DB-Landing-page-body .ant-tabs-tab-btn {
  margin: auto;
}

.App-DB-Landing-page-header .anticon-close-circle {
  vertical-align: text-bottom !important;
}    
