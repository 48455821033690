.App-pass-table-container {
	width: 100%;
  	height: 100%;
  	display: flex;
  	flex-direction: column;
  	overflow: scroll;
  	/* margin : auto 10px; */
}

.App-pass-table-container-header {
	display: flex;
	color: #161616;
  	font-family: "Proxima Nova";
  	/* font-size: 24px;
  	font-weight: bolder;
  	letter-spacing: 0.3px;
 	line-height: 29px;
 	height: 8%;
 	padding: auto 10%; */     /* -P... */
	font-weight: 600;
	font-size: 1.5rem;
	letter-spacing: 0.02rem;
	min-height: 3rem;
	border-bottom: 1px solid #D4D5D6;
	padding: 0 0.9rem;
}

.App-pass-table-container-header-title {
	margin: auto 10px;
	float: left;
}

.App-table-header {
	border-collapse: collapse;
  	padding: 0.5rem 0;
  	background-color: #F4F4F4;
  	/* border-right: 1px solid #DEDFE0; */     /* -P... */
	border: none;     /* -P... */
  	color: #161616;
  	text-align: left;
  	font-family: "Proxima Nova";
  	font-size: 14px;
  	font-weight: 600;
  	letter-spacing: 0.3px;
  	/* line-height: 24px; */
  	flex-direction: row;
  	width: 100%;
}

.App-table-header tr, .App-table-header tr th {     /* -P... */
	border: none;
}

.App-table-header tr th {     /* -P... */
	border-right: 1px solid #d4d5d6;
}

.App-table-header tr th:last-child {     /* -P... */
	border-right: none;
}

.App-table-row {
	padding: 0.5rem 0;
  	border-collapse: collapse;
  	text-align: left;
  	height : 30px;
  	border-bottom : 1px solid #E4E4E4;           /* -P */
  	font-family: 'Proxima Nova';
	font-style: normal;
	font-weight: 400;
	/* font-size: 14px;
	line-height: 14px;
	letter-spacing: 0.3px; */           /* -P */
	font-size: 0.9rem;                  /* -P */
	letter-spacing: 0.02rem;            /* -P */
	line-height: 0.9rem;                /* -P */
	color: #161616;
}

.App-table-container-body {
	height: 100%;
	overflow: scroll;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.App-DI-table-container .App-table-container-body {
	border-left: 2px solid #4285F4;
	padding-left: 1rem;    /* -P... */
}

.App-CS-table-container-table .App-table-container-body {
	border-left: 2px solid #4285F4;
}    /* -P... */

.App-table-container-body-table {
	height: 92%;
	overflow: scroll;
	margin-left: 1.5rem;     /* -P */
    margin-right: 1.5rem;    /* -P */
}

.App-DI-table-container .App-table-container-body-table {
	margin: 0;
}     /* -P */

tr.App-table-row td:first-child {     /* -P... */
	border-left: none;
}

tr.App-table-row td {     /* -P... */
	border-color: #ededed;
}

.table-hover>tbody>tr:hover>* {    /* -P... */
	background-color: #ebf4ff !important;
	--bs-table-accent-bg: transparent !important;
}

.App-table-row-cell {
	border-right: none;
	overflow: hidden;
 	text-overflow: ellipsis;
 	white-space: nowrap;
}

.App-table-container-footer {
	display: flex;
	flex-direction: row;
	border-top : 1px solid #D4D5D6;
	/* padding-top: 10px; */      /* -P */
	padding: 0.6rem 1.5rem;       /* -P */
    min-height: 3rem;             /* -P */
    font-size: 0.85rem;            /* -P */
}

.App-table-container-footer-page-size {
	display: flex;
	flex-direction: row;
	/* width: 20%; */           /* -P */
	border-right: 1px solid #D4D5D6;
	margin-right: 1.5rem;       /* -P */
}

.App-table-container-footer-page-size-dropdown {
	/* width: 50%; */
	margin: auto;
	margin-right: 0.5rem;       /* -P */
}

.App-table-container-footer-page-size-dropdown select.form-select {       /* -P */
	font-size: 0.85rem;
}

.App-table-container-footer-page-size-label {
	margin: auto;
	margin-right: 1.5rem;       /* -P */
}

.App-table-container-footer-page-link {
	/* width : 70%;
	margin: auto; */         /* -P */
	display: flex;
	flex-direction: row;
}

.App-table-container-footer-page-link-container {
	/* width : 50%;
	margin : auto; */       /* -P */
	display: flex;
	flex-direction: row;
}

.App-table-container-footer-page-link-button-fprev {
	margin : auto;
	margin-right: 1rem;     /* -P */
}

.App-table-container-footer-page-link-button-prev {
	margin: auto;
	margin-right: 1rem;     /* -P */
}

.App-table-container-footer-page-link-detail {
	margin: auto;
	margin-right: 1rem;     /* -P */
}

.App-table-container-footer-page-link-button-fnext {
	margin : auto;
	margin-right: 1rem;     /* -P */
}

.App-table-container-footer-page-link-button-next {
	margin: auto;
	margin-right: 1rem;     /* -P */
}
